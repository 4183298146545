import React from 'react'

const UnderConstructionPage = () => {
  return (
    <div className="relative h-[90vh] w-full flex items-center justify-center  text-center" >
  
      <div className="z-50 flex flex-col justify-center items-center text-black w-full h-full gap-2 opacity-60">
          {/* <span className="text-bold">LOGO</span> */}
          <h1 className="text-6xl"><b>Coming</b> Soon!</h1>
          {/* <p>Stay tuned for something amazing!!!</p> */}
        </div>
  
</div>
      
    
      

  )
}

export default UnderConstructionPage
import image1 from "./galleryPics/image_1.jpg"
import image2 from "./galleryPics/image_2.jpg"
import image3 from "./galleryPics/image_3.jpg"
import image4 from "./galleryPics/image_4.jpg"
import image5 from "./galleryPics/image_5.jpg"
import image6 from "./galleryPics/image_6.jpg"
import image7 from "./galleryPics/image_7.jpg"
import image8 from "./galleryPics/image_8.jpg"
import image9 from "./galleryPics/image_9.jpg"
import image10 from "./galleryPics/image_10.jpg"
import image11 from "./galleryPics/image_11.jpg"
import image12 from "./galleryPics/image_12.jpg"
import image13 from "./galleryPics/image_13.jpg"
import image14 from "./galleryPics/image_14.jpg"
import image15 from "./galleryPics/image_15.jpg"
import image16 from "./galleryPics/image_16.jpg"
import image17 from "./galleryPics/image_17.jpg"
import image18 from "./galleryPics/image_18.jpg"
import image19 from "./galleryPics/image_19.jpg"
import image20 from "./galleryPics/image_20.jpg"
import image21 from "./galleryPics/image_21.jpg"
import image22 from "./galleryPics/image_22.JPG"
import image23 from "./galleryPics/image_23.jpg"
import image24 from "./galleryPics/image_24.JPG"
import image25 from "./galleryPics/image_25.JPG"
import image26 from "./galleryPics/image_26.JPG"
import image27 from "./galleryPics/image_27.JPG"
import image28 from "./galleryPics/image_28.JPG"
import image29 from "./galleryPics/image_29.jpg"
import image30 from "./galleryPics/image_30.jpg"
import image31 from "./galleryPics/image_31.JPG"
import image32 from "./galleryPics/image_32.jpg"
import image33 from "./galleryPics/image_33.jpg"
import image34 from "./galleryPics/image_34.jpg"
import image35 from "./galleryPics/image_35.jpg"
const data =[
    {
        src:image32,
        thumbnail:image32,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:"VBS 1990"
    },
    {
        src:image1,
        thumbnail:image1,
        thumbnailWidth: 2,
        thumbnailHeight: 3,
        caption:"hello"
    },
    {
        src:image2,
        thumbnail:image2,
        thumbnailWidth: 3500,
        thumbnailHeight: 2333,
    }
    ,
    {
        src:image3,
        thumbnail:image3,
        thumbnailWidth: 3500,
        thumbnailHeight: 2333,
    }
    ,
    {
        src:image4,
        thumbnail:image4,
        thumbnailWidth: 3500,
        thumbnailHeight: 2333,
    }
    ,
    {
        src:image5,
        thumbnail:image5,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image6,
        thumbnail:image6,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image7,
        thumbnail:image7,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:"gorsa(2004)"
    }
    ,
    {
        src:image8,
        thumbnail:image8,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:"Bro Balasundara Rau uncle preaching at gorsa (2004)"
    }
    ,
    {
        src:image9,
        thumbnail:image9,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image10,
        thumbnail:image10,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:"Bro Sukumar Anna (gorsa 2004)"
    },
    {
        src:image11,
        thumbnail:image11,
        thumbnailWidth: 3,
        thumbnailHeight: 4,
    }
    ,
    {
        src:image12,
        thumbnail:image12,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image13,
        thumbnail:image13,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image14,
        thumbnail:image14,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image15,
        thumbnail:image15,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image16,
        thumbnail:image16,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image17,
        thumbnail:image17,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image18,
        thumbnail:image18,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image19,
        thumbnail:image19,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image20,
        thumbnail:image20,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image21,
        thumbnail:image21,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image22,
        thumbnail:image22,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image23,
        thumbnail:image23,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image24,
        thumbnail:image24,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image25,
        thumbnail:image25,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image26,
        thumbnail:image26,
        thumbnailWidth: 2,
        thumbnailHeight: 3,
    }
    ,
    {
        src:image27,
        thumbnail:image27,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image28,
        thumbnail:image28,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image29,
        thumbnail:image29,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image30,
        thumbnail:image30,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
    }
    ,
    {
        src:image31,
        thumbnail:image31,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:"VBS 2011 teachers"
    }
    ,
    {
        src:image35,
        thumbnail:image35,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:""
    }
    ,
    {
        src:image33,
        thumbnail:image33,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:""
    }
    ,
    {
        src:image34,
        thumbnail:image34,
        thumbnailWidth: 3,
        thumbnailHeight: 2,
        caption:""
    }
    
    

]

export default data;